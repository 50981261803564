import { IProductAccessory, IProductAccessoryPayment } from "./IProductAccessory";
import { IProductFile } from "./IProductFile";
export const gaMeasurementId = process.env.GTM_ID;

export interface IProductDetail {
    accessories: IProductAccessory[],
    allowed_sell: boolean,
    allowed_split: boolean,
    amount: number,
    attribute_values: number[],
    code: string,
    product_code?: string | null,
    group_id: number,
    id: number | any,
    is_set: boolean,
    original_price: number,
    price: number,
    rental_price: number,
    is_product?: boolean | null,
    is_bundling?: boolean | null,
    is_main_bundling?: boolean | null,
    bundling_id?: number | any,
    original_rental_price: number,
}

export interface IProductDetailPayment extends IProductDetail {
    qty: number | null,
    is_purchase?: boolean | null,
    is_full_set?: boolean,
    product_amount?: number | null,
    name: string,
    take_date: string,
    return_date: string,
    checked: boolean,
    accessories: IProductAccessoryPayment[],
    temp_accessories: IProductAccessoryPayment[],
    attribute_choose: number[],
    files: IProductFile[],
    weight: number | any,
    set_qty: number | any

}
